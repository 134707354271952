import React from "react";
import ReactDOM from "react-dom/client";
import SSO from "@code-skills-community/sso-react-client";
import 'katex/dist/katex.min.css'
import "./styles/index.css";
import "./styles/global.css";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <SSO.Provider>
    <App />
  </SSO.Provider>
  // </React.StrictMode>
);
