import { Me } from '@code-skills-community/react-user';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { FaMarkdown } from 'react-icons/fa';
import { VscCommentDiscussion } from 'react-icons/vsc';
import Previewer from './Previewer';
import autosize from 'autosize';

const Editor = ({ handlePost, placeholder }) => {
  const [text, setText] = useState(placeholder || '');
  const [preview, setPreview] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const textareaRef = useRef(null);
  const [height, setHeight] = useState('96px');

  useEffect(() => {
    autosize(textareaRef.current);
    // console.log({ s: textareaRef.current.style.height });
    if (textareaRef.current) setHeight(textareaRef.current.style.height);
  }, [text]);

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  const submitHandler = async () => {
    setIsSubmitting(true);
    await handlePost(text);

    setIsSubmitting(false);
    setText('');
  };

  return (
    <div className='relative w-full px-2 py-3 rounded-lg operateCard'>
      <PostButton
        disabled={isSubmitting || !text}
        handlePost={submitHandler}
        text={isSubmitting ? 'Posting...' : 'Post'}
      />
      <div className='flex items-baseline justify-start'>
        <p
          className={`${
            !preview ? 'text-accent font-bold' : ''
          }  px-2 py-1  cursor-pointer rounded-t-md `}
          onClick={() => setPreview(false)}
        >
          Write
        </p>
        <p
          className={`${
            preview ? 'text-accent font-bold' : ''
          }  px-2 py-1 cursor-pointer rounded-t-md`}
          onClick={() => setPreview(true)}
        >
          Preview
        </p>
      </div>
      <div className={`py-1 px-2 w-full `}>
        {preview ? (
          <Previewer text={text} discussion />
        ) : (
          <textarea
            className={`w-full min-h-[6rem] text-sm rounded-md bg-[#F7F8FA]  text-black`}
            value={text}
            onChange={(e) => handleTextChange(e)}
            ref={textareaRef}
            style={{
              height: `${height}`
            }}
          />
        )}
        {!preview && (
          <div className='flex items-center justify-start'>
            <FaMarkdown />
            <p className='ml-2 text-xs opacity-80'>
              Styling with Markdown is supported
            </p>
          </div>
        )}
      </div>

      <div className='absolute right-0 bottom-0'></div>
    </div>
  );
};

export default Editor;

Editor.propTypes = {
  handlePost: PropTypes.func.isRequired
};

const PostButton = ({ handlePost, disabled, text }) => {
  return (
    <div className='absolute right-2 top-2 flex items-center justify-between m-auto '>
      <button
        disabled={disabled}
        className={`flex items-center justify-center mr-2 w-max p-2 lg:py-2 lg:px-4 cursor-pointer rounded-md shadow-lg ${
          disabled ? 'bg-gray-400' : 'bg-primary-bgColorDark'
        }`}
        onClick={() => handlePost()}
      >
        <VscCommentDiscussion color='white' />
        <p className='text-xs w-max font-bold customFont text-white ml-2'>
          {text}
        </p>
      </button>
      <div>
        <Me showName={false} defaultText=' ' />
      </div>
    </div>
  );
};
