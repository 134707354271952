import React from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import { PrismAsyncLight as SyntaxHighlighter } from "react-syntax-highlighter";
import { coldarkDark as dark } from "react-syntax-highlighter/dist/cjs/styles/prism";
import remarkGfm from "remark-gfm";
import RemarkMathPlugin from "remark-math";
import rehypeKatex from "rehype-katex";

const Previewer = ({ text, discussion = false }) => {
  return (
    <ReactMarkdown
      className={`p-2 text-xs prose max-w-full ${
        discussion ? "overflow-y-auto min-h-[5rem]" : ""
      } overflow-x-auto`}
      children={text}
      remarkPlugins={[remarkGfm, RemarkMathPlugin]}
      rehypePlugins={[rehypeKatex]}
      components={{
        code({ node, inline, className, children, ...props }) {
          const match = /language-(\w+)/.exec(className || "");
          return !inline && match ? (
            <SyntaxHighlighter
              children={String(children).replace(/\n$/, "")}
              style={dark}
              language={match[1]}
              PreTag="div"
              {...props}
            />
          ) : (
            <code className={className} {...props}>
              {children}
            </code>
          );
        },
      }}
    />
  );
};

export default Previewer;

Previewer.propTypes = {
  text: PropTypes.string.isRequired,
  discussion: PropTypes.bool,
};
