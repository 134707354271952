import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { AiFillLike } from 'react-icons/ai';
import { ApiContext } from '../../contexts/ApiContext';

const CommentLike = ({ commentId, like, isLiked }) => {
  const { api, isLoggedIn } = useContext(ApiContext);
  const [likeCount, setLikeCount] = useState(0);
  const [liked, setLiked] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setLikeCount(like);
  }, [like]);

  useEffect(() => {
    setLiked(isLiked);
  }, [isLiked]);

  const onClickHandler = async () => {
    if (!isLoggedIn) return;

    setDisabled(true);

    setLiked((cur) => !cur);
    if (liked) {
      setLikeCount((cur) => cur - 1);
    } else {
      setLikeCount((cur) => cur + 1);
    }

    const options = {
      method: 'GET',
      url: `/comment/${commentId}/like`
    };
    await api(options);

    setDisabled(false);
  };

  return (
    <div className='flex items-center mr-1'>
      <p className='text-gray-500 mr-1 mb-0'>{likeCount || ''}</p>
      <button onClick={onClickHandler} disabled={disabled}>
        <AiFillLike
          className={`mb-[3px] text-lg ${
            liked ? 'text-like' : 'text-gray-500'
          }`}
        />
      </button>
    </div>
  );
};

export default CommentLike;

CommentLike.propTypes = {
  commentId: PropTypes.string,
  like: PropTypes.number,
  isLiked: PropTypes.bool
};
