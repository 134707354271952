import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { PrismAsyncLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import { coldarkDark as dark } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import remarkGfm from 'remark-gfm';
import { User } from '@code-skills-community/react-user';
import Time from 'react-time/lib/Time';
import RemarkMathPlugin from 'remark-math';
import rehypeKatex from 'rehype-katex';
import CommentLike from './CommentLike';
import CommentOptions from './CommentOptions';
import { useMe } from '@code-skills-community/react-user';

export const Comment = ({
  _id,
  text,
  like,
  isLiked,
  updatedAt,
  user,
  deleteComment
}) => {
  const { me } = useMe();
  const owner = me?._id === user._id;

  return (
    <div className='rounded-md w-auto bg-[#F7F8FA] relative mx-auto my-4 p-4 shadow-sm'>
      <div
        className={`absolute flex items-center justify-end w-max m-auto ${
          owner ? 'right-2 top-1' : 'right-3 top-3'
        }`}
      >
        <CommentLike commentId={_id} like={like} isLiked={isLiked} />
        {owner && (
          <CommentOptions commentId={_id} deleteComment={deleteComment} />
        )}
      </div>
      <div className='pt-4 pb-2 flex items-center justify-start m-auto h-full'>
        {user && <User userId={user._id} username={user.username} />}

        {updatedAt && (
          <>
            <span className='mx-1 text-gray-400'>&#x2022;</span>
            <small className='text-gray-500'>
              <Time value={updatedAt} relative />
            </small>
          </>
        )}
      </div>

      <div className='pt-2 my-2'>
        <ReactMarkdown
          className='p-1 text-xs prose min-h-[3rem] max-h-[300px] max-w-full overflow-x-hidden'
          children={text}
          remarkPlugins={[remarkGfm, RemarkMathPlugin]}
          rehypePlugins={[rehypeKatex]}
          components={{
            code({ node, inline, className, children, ...props }) {
              const match = /language-(\w+)/.exec(className || '');
              return !inline && match ? (
                <SyntaxHighlighter
                  children={String(children).replace(/\n$/, '')}
                  style={dark}
                  language={match[1]}
                  PreTag='div'
                  {...props}
                />
              ) : (
                <code className={className} {...props}>
                  {children}
                </code>
              );
            }
          }}
        />
      </div>
    </div>
  );
};

Comment.propTypes = {
  _id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  like: PropTypes.number,
  isLiked: PropTypes.bool,
  updatedAt: PropTypes.string.isRequired,
  user: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    avatar: PropTypes.string
  })
};

export default Comment;
