import React from "react";

const NotFound = () => {
  return (
    <div className="h-screen w-screen flex flex-col justify-center items-center">
      <div className="mb-6">
        <h1 className="text-6xl">404</h1>
      </div>
      <p>Discussion Not Found!</p>
    </div>
  );
};

export default NotFound;
