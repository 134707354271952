import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import Comment from './Comment';
import Editor from '../common/Editor';
import { ApiContext } from '../../contexts/ApiContext';
import InfiniteScroll from 'react-infinite-scroll-component';
import io from 'socket.io-client';
import SSO from '@code-skills-community/sso-react-client';

const socketUrl = process.env.REACT_APP_SOCKET_BASE_URL;

const CommentList = ({ discussionId }) => {
  const { api, isLoggedIn } = useContext(ApiContext);
  const { getAuthToken } = useContext(SSO.Context);
  const [comments, setComments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(2);
  const socketRef = useRef();

  useEffect(() => {
    const getData = async () => {
      const resposne = await api({
        method: 'GET',
        url: '/comment/list',
        params: {
          discussionId,
          page: 1
        }
      });

      if (resposne?.data?.data) {
        const data = resposne.data.data;

        if (data?.length < 25) {
          setHasMore(false);
        }

        setComments(data);
        setIsLoading(false);
      }
    };

    getData();

    //eslint-disable-next-line
  }, [discussionId]);

  const isPresent = (commentId) => {
    return comments.find((cmt) => cmt._id === commentId);
  };

  const socketHandler = useCallback(() => {
    // console.log("connected", socketRef.current);

    socketRef.current.on('created', (newComment) => {
      // console.log(newComment);
      setComments((cur) => [newComment, ...cur]);
    });

    socketRef.current.on('updated', (updatedComment) => {
      // console.log(updatedComment);
      setComments((cur) =>
        cur.map((cmt) =>
          cmt._id === updatedComment._id ? updatedComment : cmt
        )
      );
    });

    socketRef.current.on('deleted', (cmt) => {
      setComments((cur) => cur.filter((c) => c._id !== cmt._id));
    });
  }, []);

  useEffect(() => {
    const connectSocket = async () => {
      const token = await getAuthToken();

      socketRef.current = io(`${socketUrl}/discussion?id=${discussionId}`, {
        extraHeaders: {
          authorization: token
        }
      });

      socketRef.current.on('connect', socketHandler);
    };

    connectSocket();

    // eslint-disable-next-line
  }, []);

  const handleSubmit = async (text) => {
    // console.log({ text });
    await api({
      method: 'POST',
      url: '/comment',
      data: {
        text,
        discussionId
      }
    });

    // const res = resposne?.data?.data;
    // if (res) setComments((cur) => [res, ...cur]);
  };

  const fetchMore = async () => {
    const resposne = await api({
      method: 'GET',
      url: '/comment/list',
      params: {
        discussionId,
        page: page
      }
    });

    setPage((cur) => cur + 1);
    // console.log(resposne?.data?.data);

    if (resposne?.data?.data?.length > 0)
      setComments((cur) => [
        ...cur,
        ...resposne.data.data.filter((e) => isPresent(e._id))
      ]);
    else setHasMore(false);
  };

  const deleteComment = (_id) => {
    api({
      method: 'DELETE',
      url: `/comment/${_id}`
    });
    
    setComments((cur) => cur.filter((c) => c._id !== _id));
  };

  return (
    <>
      {/* comment box */}
      {isLoggedIn && (
        <Editor
          handlePost={handleSubmit}
          placeholder='👈 Write your comment!'
        />
      )}

      {/* comment listing */}
      <div className='mt-1 px-2 lg:pb-4'>
        {comments.length > 0 && (
          <InfiniteScroll
            dataLength={comments.length}
            hasMore={hasMore}
            next={fetchMore}
            loader={<p className='text-center'>Loading...</p>}
          >
            <>
              {comments.map((item) => (
                <Comment
                  key={item._id.toString()}
                  {...item}
                  deleteComment={deleteComment}
                />
              ))}
            </>
          </InfiniteScroll>
        )}

        {!isLoading && comments.length === 0 && (
          <div className='mt-4'>
            <p className='text-center text-sm text-gray-500'>
              Nothing has been discussed yet!
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default CommentList;
