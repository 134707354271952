import { ClickAwayListener } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { BiDotsVertical } from 'react-icons/bi';
import { MdDelete } from 'react-icons/md';

const CommentOptions = ({ commentId, deleteComment }) => {
  const [open, setOpen] = useState(false);

  const handleDelete = () => {
    setOpen(false);

    if (!window.confirm('Do you really want to delete the comment?')) {
      return;
    }

    deleteComment(commentId);
  };

  return (
    <div className='relative'>
      <button
        className='ml-1 p-2 hover:bg-gray-200 rounded-full'
        onClick={() => setOpen((cur) => !cur)}
      >
        <BiDotsVertical className='text-gray-600' />
      </button>

      {open && (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <div className='bg-white comment-options p-1 rounded-sm absolute bottom-0 left-0 translate-x-[-60%] translate-y-[95%] drop-shadow'>
            <ul>
              <li
                className='px-2 py-1 hover:bg-gray-200 text-sm cursor-pointer flex items-center'
                onClick={handleDelete}
              >
                <span>
                  <MdDelete className='text-lg text-red-500' />
                </span>
                <span className='pl-1 mt-1 text-gray-800'>Delete</span>
              </li>
            </ul>
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default CommentOptions;
