import { CircularProgress } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CommentList from '../components/comment/CommentList';
import { ApiContext } from '../contexts/ApiContext';
// import Previewer from "../components/common/Previewer";

const Post = () => {
  const { api } = useContext(ApiContext);

  const [loading, setLoading] = useState(true);
  const [discussionId, setDiscussionId] = useState(true);
  const history = useNavigate();
  // const [postData, setPostData] = useState(null);

  useEffect(() => {
    const getData = async (id) => {
      const resposne = await api({
        method: 'GET',
        url: `/discussion/${id}`
      });

      if (!resposne?.data?.data) {
        history('/not-found');
      }

      setLoading(false);

      // if (resposne?.data?.data) setComments(resposne?.data?.data);
    };

    const query = new URLSearchParams(window.location.search);
    const id = query.get('discussionId');

    // if (!id) histroy("/not-found");
    if (!id) window.location.replace(process.env.REACT_APP_REDIRECT_URL);

    if (id) {
      getData(id);
      setDiscussionId(id);
    }

    //eslint-disable-next-line
  }, []);

  if (loading)
    return (
      <div className='m-auto h-full w-full py-6 flex items-center justify-center'>
        <CircularProgress size={30} />
      </div>
    );

  return (
    <div className='customScroll w-full overflow-y-scroll pr-2 lg:pb-4 overflow-x-hidden p-2'>
      <CommentList discussionId={discussionId} />
    </div>
  );
};

export default Post;
