import { ApiProvider } from "./contexts/ApiContext";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Post from "./pages/Post";
// import Home from "./pages/Home";
import NotFound from "./pages/NotFound";

function App() {
  return (
    <div className="App">
      <ApiProvider>
        <Router>
          <Routes>
            {/* <Route path="/home" element={<Home />}></Route>
            <Route path="/:discussionId" element={<Post />}></Route> */}
            
            <Route path="/" element={<Post />}></Route>

            <Route path="*" element={<NotFound />}></Route>
          </Routes>
        </Router>
      </ApiProvider>
    </div>
  );
}

export default App;
