import SSO from "@code-skills-community/sso-react-client";
import axios from "axios";
import React, { createContext, useContext } from "react";

const CustomSSOContext = createContext();

const CustomApiWithSSOProvider = ({ children }) => {
  const { getAuthToken, isLoggedIn } = useContext(SSO.Context);

  const api = async ({ headers = {}, ...rest }) => {
    const token = await getAuthToken(true);
    // console.log(token);

    return await axios({
      baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
      ...rest,
      headers: {
        ...headers,
        Authorization: token,
      },
    }).catch((err) => err);
  };

  return (
    <CustomSSOContext.Provider value={{ api, isLoggedIn }}>
      {children}
    </CustomSSOContext.Provider>
  );
};

export {
  CustomApiWithSSOProvider as ApiProvider,
  CustomSSOContext as ApiContext,
};
